<template>
	<div v-if="ll" class="temain">
		<div class="tecontent center">
			<div>
				<h1 data-ll="6622">{{ll.i6622}}</h1>
				<span class="tesmallline"></span>
				<p class="teop" data-ll="6623">{{ll.i6623}}</p>
			</div>
			<div>
				<a class="teblock" :href="`#VideoTestimonials`">
					<div class="teblock2">
						<div class="teimg"><img class="youtube" src="/img/testimonials/2019/youtube.png"></div>
						<div class="tetitle">{{countTestimonials.video}}</div>
						<div class="tename" data-ll="6624">{{ll.i6624}}</div>
					</div>
				</a>

				<a class="teblock" :href="`#WrittenTestimonials`">
					<div class="teblock2">
						<div class="teimg"><img class="edit" src="/img/testimonials/2019/edit.png"></div>
						<div class="tetitle">{{countTestimonials.written}}</div>
						<div class="tename" data-ll="6625">{{ll.i6625}}</div>
					</div>
				</a>

				<a class="teblock last" :href="`#Cases`">
					<div class="teblock2">
						<div class="teimg"><img class="office" src="/img/testimonials/2019/office-briefcase.png"></div>
						<div class="tetitle">{{countTestimonials.cases}}</div>
						<div class="tename" data-ll="6626">{{ll.i6626}}</div>
					</div>
				</a>
			</div>
			<div>

				<a target="_blank" href="https://www.google.com/search?q=semalt&rlz=1C1SQJL_ruUA862UA862&oq=semalt+&aqs=chrome..69i57j69i60l5.1056j0j7&sourceid=chrome&ie=UTF-8#lrd=0x40d4cee197f0f279:0x4a4140be8c7d0822,1,," class="googlerew">
					<div class="googlelogo">
						<svg class="Path_374" viewBox="272.1 223.1 27.542 26.92">
							<path fill="rgba(66,133,244,1)" id="Path_374" d="M 299.6417541503906 228.926513671875 C 299.6417541503906 226.977294921875 299.4837036132813 225.0175476074219 299.1465454101563 223.0999755859375 L 272.1000061035156 223.0999755859375 L 272.1000061035156 234.1419677734375 L 287.5883178710938 234.1419677734375 C 286.945556640625 237.7032165527344 284.8804321289063 240.8535766601563 281.8565673828125 242.8554382324219 L 281.8565673828125 250.0200805664063 L 291.0968322753906 250.0200805664063 C 296.5230102539063 245.0259094238281 299.6417541503906 237.6505432128906 299.6417541503906 228.926513671875 Z">
							</path>
						</svg>
						<svg class="Path_375" viewBox="28.9 324.3 44.632 23.18">
							<path fill="rgba(52,168,83,1)" id="Path_375" d="M 54.52415084838867 347.4797058105469 C 62.25775527954102 347.4797058105469 68.77969360351563 344.9404907226563 73.53153228759766 340.5574035644531 L 64.29124450683594 333.3927307128906 C 61.72040176391602 335.1417541503906 58.40148544311523 336.1322021484375 54.53468322753906 336.1322021484375 C 47.05395126342773 336.1322021484375 40.71112823486328 331.0852966308594 38.435302734375 324.2999877929688 L 28.90000152587891 324.2999877929688 L 28.90000152587891 331.6859130859375 C 33.76774215698242 341.3687133789063 43.68235397338867 347.4797058105469 54.52415084838867 347.4797058105469 Z">
							</path>
						</svg>
						<svg class="Path_376" viewBox="-0.05 150 12.575 25.751">
							<path fill="rgba(251,188,4,1)" id="Path_376" d="M 12.52501201629639 168.3646697998047 C 11.32387828826904 164.8034362792969 11.32387828826904 160.9471588134766 12.52501201629639 157.3859100341797 L 12.52501201629639 150 L 3.000243425369263 150 C -1.066746830940247 158.1023712158203 -1.066746830940247 167.6482086181641 3.000243425369263 175.7505950927734 L 12.52501201629639 168.3646697998047 Z">
							</path>
						</svg>
						<svg class="Path_377" viewBox="28.9 -0.018 44.811 23.192">
							<path fill="rgba(234,67,53,1)" id="Path_377" d="M 54.52415084838867 11.33112716674805 C 58.61221313476563 11.26791095733643 62.56330108642578 12.80620193481445 65.52398681640625 15.62991619110107 L 65.52398681640625 15.62991619110107 L 73.71065521240234 7.443253993988037 C 68.52681732177734 2.575507879257202 61.64665603637695 -0.1006985008716583 54.52415084838867 -0.0164085365831852 C 43.68235397338867 -0.0164085365831852 33.76774215698242 6.094613075256348 28.90000152587891 15.78795909881592 L 38.42476654052734 23.17386627197266 C 40.69005966186523 16.37798881530762 47.04341506958008 11.33112716674805 54.52415084838867 11.33112716674805 Z">
							</path>
						</svg>
					</div>
					<div class="" data-ll="6627">{{ll.i6627}}</div>
					<div><span class="reit">4.6</span>
						<span class="starts">
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(255,157,0,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(255,157,0,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(255,157,0,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(255,157,0,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>

						<div class="star_24px_50">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(81,81,81,1)" id="Path_371_A1_Path_35" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
							<svg class="Path_373" viewBox="2 2 8.759 16.642">
								<path fill="rgba(255,157,0,1)" id="Path_373" d="M 10.75878143310547 15.37466049194336 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>

					</span>
					</div>
				</a>



				<a href="https://www.facebook.com/SemaltCompany/" target="_blank" class="googlerew fbrerew">
					<div class="F_icon">
						<svg class="Blue_1_" viewBox="4.588 4.588 55.203 55.203">
							<path fill="rgba(60,90,153,1)" id="Blue_1_" d="M 56.74443435668945 59.79143905639648 C 58.42676162719727 59.79143905639648 59.7910041809082 58.42719650268555 59.7910041809082 56.74443435668945 L 59.7910041809082 7.634782314300537 C 59.7910041809082 5.951812267303467 58.42698287963867 4.588000297546387 56.74443435668945 4.588000297546387 L 7.634782314300537 4.588000297546387 C 5.951812267303467 4.588000297546387 4.588000297546387 5.951812267303467 4.588000297546387 7.634782314300537 L 4.588000297546387 56.74443435668945 C 4.588000297546387 58.42698287963867 5.951598167419434 59.79143905639648 7.634782314300537 59.79143905639648 L 56.74443435668945 59.79143905639648 Z">
							</path>
						</svg>
						<svg class="fbb" viewBox="108.658 43.512 24.338 46.866">
							<path fill="rgba(255,255,255,1)" id="f" d="M 124.4554748535156 90.37791442871094 L 124.4554748535156 69.00008392333984 L 131.6309509277344 69.00008392333984 L 132.7053985595703 60.66878128051758 L 124.4554748535156 60.66878128051758 L 124.4554748535156 55.34976959228516 C 124.4554748535156 52.93765640258789 125.1252746582031 51.29388809204102 128.5841827392578 51.29388809204102 L 132.995849609375 51.29196166992188 L 132.995849609375 43.84037017822266 C 132.2328643798828 43.73883438110352 129.6140594482422 43.51199722290039 126.5672760009766 43.51199722290039 C 120.2065963745117 43.51199722290039 115.8519058227539 47.39458847045898 115.8519058227539 54.5246696472168 L 115.8519058227539 60.66878128051758 L 108.6580047607422 60.66878128051758 L 108.6580047607422 69.00008392333984 L 115.8519058227539 69.00008392333984 L 115.8519058227539 90.37791442871094 L 124.4554748535156 90.37791442871094 Z">
							</path>
						</svg>
					</div>
					<div class="" data-ll="6628">{{ll.i6628}}</div>
					<div><span class="reitfb">4.9</span>
						<span class="starts">
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(60,90,153,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(60,90,153,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(60,90,153,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>
						<div class="star_24px">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(60,90,153,1)" id="Path_371" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>

						<div class="star_24px_50">
							<svg class="Path_371" viewBox="2 2 17.518 16.642">
								<path fill="rgba(81,81,81,1)" id="Path_371_A1_Path_35" d="M 10.75878143310547 15.37466049194336 L 16.17171096801758 18.64168548583984 L 14.73526954650879 12.48426151275635 L 19.51756477355957 8.341358184814453 L 13.21999931335449 7.807072639465332 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
							<svg class="Path_373" viewBox="2 2 8.759 16.642">
								<path fill="rgba(60,90,153,1)" id="Path_373" d="M 10.75878143310547 15.37466049194336 L 10.75878143310547 2 L 8.297563552856445 7.807072639465332 L 2 8.341358184814453 L 6.782294750213623 12.48426151275635 L 5.345854759216309 18.64168548583984 L 10.75878143310547 15.37466049194336 Z">
								</path>
							</svg>
						</div>

					</span>
					</div>
				</a>
			</div>
		</div>

		<div class="roundblock"><div class="roundblock2"></div></div>
		<div class="block1">
			<div class="tnavigate" id="VideoTestimonials"></div>
			<h2 class="h2" data-ll="6629">{{ll.i6629}}</h2>
			<div class="filters">
				<span class="namefilter" data-ll="6630">{{ll.i6630}}</span>
				<sselect v-if="ll.i6631" @change="changeCategVideo" :placeholder="ll.i6631" v-bind:post="categVideo"  v-model="keyCategVideo"></sselect>
				<!--
				<div class="select">
					<select  @change="changeCategVideo" v-model="keyCategVideo">
						<option value="0">{{ll.i6631}}</option>
						<option v-for="(row, key) in categVideo" :key="row.id" :value="key">{{row}}</option>
					</select>
					<i></i>
				</div>
				-->
			</div>
		</div>

		<div class="block2">
			<div class="videoblocks2">
				<div v-for="row in videos" :key="row.id" class="blockvideo" :data-id="row.id">
					<div class="blockvideo2 ">
						<table>
							<tr><td data-ll="6632">{{ll.i6632}}</td>
								<td class="tbold">{{row.name}}</td></tr>
							<tr><td data-ll="6633">{{ll.i6633}}</td>
								<td class="tbold h50">{{row.work}}
									<template v-if="row.link_website">
										<a :href="row.link_website" target="_blank">{{row.company_name}}</a>
									</template>
									<template v-else>
										{{row.company_name}}
									</template>
								</td></tr>
							<tr><td data-ll="6634">{{ll.i6634}}</td>
								<td class="tbold h50">{{row.company_desc}}</td></tr>
							<tr><td data-ll="6635">{{ll.i6635}}</td>
								<td class="tbold"><span class="flag3"><img :src="'/img/flags2/'+row.flag+'.png'" /></span>{{row.country}}</td></tr>
						</table>
						<div class="tvideo">
							<div v-if="row.play==0">
								<div class="tplay" @click="row.play=1"></div>
								<div class="tvideocontent">
									<img :src="row.img" />
								</div>
							</div>
							<div v-else>
								<iframe :src="row.video+'?autoplay=1'" width="350" height="205" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>

			<div v-if="showmorevideo" class="more" @click="morevideo" data-ll="6636">{{ll.i6636}}</div>
		</div>

		<div class="block3">
			<div class="tnavigate" id="WrittenTestimonials"></div>
			<h2 class="h2" data-ll="6637">{{ll.i6637}}</h2>
			<div class="filters">
				<span class="namefilter" data-ll="6630">{{ll.i6630}}</span>

				<sselect @change="changeFilterCasesText" v-if="ll.i6631" :placeholder="ll.i6631" v-bind:post="filterCasesText"  v-model="keyFilterCasesText"></sselect>
				<!--
				<div class="select">
					<select @change="changeFilterCasesText" v-model="keyFilterCasesText">
						<option value="0">{{ll.i6631}}</option>
							<option v-for="(row, key) in filterCasesText" :key="key" :value="row.flag">{{row.country}}</option>
					</select>
					<i></i>
				</div>
				-->
			</div>
		</div>

		<div class="block2">
			<div class="videoblocks2">
				<div v-for="(row, index) in arrCasesText" :data-id="index" :key="row.id" class="blockvideo blockvideotexts">
					<div class="blockvideo2">
						<table>
							<tr><td data-ll="6632">{{ll.i6632}}</td>
								<td class="tbold">{{row.name}}</td></tr>
							<tr><td data-ll="6633">{{ll.i6633}}</td>
								<td class="tbold">{{row.work}}
									<template v-if="row.link_website">
										<a :href="filterUrl(row.link_website)" target="_blank">{{row.company_name}}</a>
									</template>
									<template v-else>
										{{row.company_name}}
									</template>
								</td></tr>
							<tr><td data-ll="6634">{{ll.i6634}}</td>
								<td class="tbold">{{row.company_desc}}</td></tr>
							<tr><td data-ll="6635">{{ll.i6635}}</td>
								<td class="tbold"><span class="flag3"><img :src="'/img/flags2/'+row.flag+'.png'" /></span>{{row.country}}</td></tr>
						</table>
						<div class="tvideo tvideobg">
							<span class="quote"></span>
							<span>{{row.text}}</span>
						</div>
					</div>
				</div>
			</div>

			<div v-if="showMoreText" class="more" @click="getCasesText()" data-ll="6636">{{ll.i6636}}</div>
		</div>
		<div class="clear"></div>
		<div class="block3 bg3">
			<div class="tnavigate" id="Cases"></div>
			<h2 class="h2" data-ll="6638">{{ll.i6638}}</h2>
			<div class="filters">
				<span class="namefilter" data-ll="6630">{{ll.i6630}}</span>
				<!--
				<div class="select">
					<select @change="changefilterIndustry" v-model="keyfilterIndustry">
						<option value="0">{{ll.i6639}}</option>
						<option v-for="(row, key) in filterIndustry" :key="key" :value="row.s6_risk_2_min">{{row.s6_risk_2_min}}</option>
					</select>
					<i></i>
				</div>-->

				<sselect @change="changefilterIndustry" v-if="ll.i6639" v-bind:placeholder="ll.i6639" v-bind:post="filterIndustry"  v-model="keyfilterIndustry"></sselect>
				<span class="width20"></span>

				<sselect @change="changefilterRegion" v-if="ll.i6631" v-bind:placeholder="ll.i6631" v-bind:post="filterRegion"  v-model="keyfilterRegion"></sselect>
				<!--
				<div class="select">

					<select @change="changefilterRegion" v-model="keyfilterRegion">
						<option value="0">{{ll.i6640}}</option>
						<option v-for="(row, key) in filterRegion" :key="key" :value="row.s6_risk_3_max">{{row.s6_risk_3_max}}</option>
					</select>
					<i></i>
				</div>-->

			</div>
		</div>

		<div class="block7">
			<div class="seo-cases__list-wrap">
				<div class="seo-cases__title-wrap">
					<h3 class="seo-cases__title--shadow">Cases</h3>
					<h3 class="seo-cases__title">{{ll.i6626}}</h3>
				</div>
				<div class="container-fluid">
					<div class="seo-cases__list">
						<div v-for="(row, key) in arrCases" :key="key"   class="seo-cases__item" data-region="ua" data-industry="Услуги по ремонту техники Apple" filtered="">
							<div  class="seo-cases__item__content"><!-- v-if="row.s1_show_catalog" -->
								<div class="seo-cases__item__img" v-if="!row.s1_catalog">
									<img src="/img/cases/seo-case-bg.png" alt="">
								</div>
								<div class="seo-cases__item__img" v-else>
									<img :src="'https://cases.supersemalt.com/assets/img/uploads/'+row.s1_catalog" alt="">
								</div>
								<div class="seo-cases__item__info">
									<div class="seo-cases__item__info--top">
										<a v-if="!row.s6_hide_domain" :href="'http://'+row.proj_domain" target="_blank">{{row.proj_domain}}</a>
									</div>
									<div v-if="row.s1_niche" class="seo-cases__item__info--niche">
										<table>
											<tr>
												<td class="niche_name" data-ll="6705">{{ll.i6705}}</td>
												<td class="niche_text">{{row.s1_niche}}</td>
											</tr>
										</table>

									</div>
									<div class="seo-cases__item__info--bottom">
										<div>
											<h3>+{{row.s2_res_visits}} %</h3>
											<p data-ll="6704">{{ll.i6704}}</p>
										</div>
										<div class="seo-cases__item__info__region">
											<span>{{row.flag}}</span>
											<div class="flag2"><img :src="'/img/flags2/'+row.flag.toUpperCase()+'.png'" /></div>
										</div>
										<div class="seo-cases__item__info__hover">
											<a :href="'https://semalt.com/cases/'+row.hash_link" data-ll="6703">{{ll.i6703}}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="showMoreCases" class="more" @click="getAllCases()" data-ll="6636">{{ll.i6636}}</div>
			</div>
		</div>

		<div class="blocksupport">

			<BlockRequest></BlockRequest>
		</div>

	</div>
</template>

<script>
	/* eslint-disable */
	import axios from 'axios'
	import BlockRequest from '../components/BlockRequest';
	import Sselect from "../components/Sselect";

	export default {
		name: 'clienttestimonials',
		data: function() {
			return  {
				//lang: "en",
				msg: "Client Testimonials",
				ll: null,
				getVideoTrigger: false,
				videos: [],
				videoparams: [0, 30],
				showmorevideo: true,
				categVideo: [{id:0,val:"All", country:"int"}],
				keyCategVideo: 0,
				arrCasesText: [],
				arrCasesTextParams: [0, 30],
				showMoreText: true,
				arrCases: [],
				arrCasesParams: [0, 30],
				showMoreCases: true,
				filterCasesText: [ {id:0,val:"All", country:"int"} ],
				filterRegion: [ {id:0,val:"All", country:"int"} ],
				filterIndustry: [ {id:0,val:"All"} ],
				keyFilterCasesText:0,
				keyfilterIndustry: 0,
				keyfilterRegion: 0,
				countTestimonials:{
					'video':26,
					'written':3,
					'cases':24
				}
			}
		},
		components: {
			Sselect,
			BlockRequest
		},
		watch:{
			lang(to, from){
				this.getll();
			},
			'$route' (to, from) {
				console.log(to, from);
			}
		},
		methods: {
			async init(){
				this.route();

				await this.getll();
				this.getcategvideo();
				this.getVideoTrigger = true;
				this.getVideo(this.keyCategVideo.id, this.videoparams[0],this.videoparams[1], 0);
				this.videoparams[0] += this.videoparams[1];

				this.getCasesText();
				this.getAllCases();
				this.getRegion();
				this.getIndustry();

				let getCasesText = await this.getCases('getFilterCasesText');
				this.filterCasesText = this.filterCasesText.concat(getCasesText);
				//this.keyfilterCasesText = this.filterCasesText[0];

			},
			async getll(){
				let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["client-testimonials","cases"]}, controller:[{name:"countTestimonials",data:1}] });
				if(res && res.data) {
					if(res.data.datall){
						this.ll = res.data.datall;
						this.afterll();
					}
					if(res.data.countTestimonials){
						this.countTestimonials = res.data.countTestimonials;
					}
				}
			},
			afterll(){
				this.categVideo[0].val = this.ll.i6643;
				this.filterCasesText[0].val = this.ll.i6643;
				this.filterRegion[0].val = this.ll.i6643;
				this.filterIndustry[0].val = this.ll.i6643;
			},
			route(){
				if(this.$route.params.lang){
					if(this.$root.languages.includes(this.$route.params.lang)){
						if(this.$route.params.lang != this.$root.lang) {
							this.$root.lang = this.$route.params.lang;
						}
					}
					else{
						this.$router.push('/en/client-testimonials');
						this.$router.push('/client-testimonials');
						this.$root.lang = 'en';
					}
				}
			},
			onScrollToBottom() {
				/*if(!this.getVideoTrigger){
					this.getVideoTrigger = true;
					this.getVideo(this.keyCategVideo, this.videoparams[0],this.videoparams[1], 0);
					this.videoparams[0] += this.videoparams[1];
				}*/
			},
			async getVideo(categ, start, limit, refresh){
				let res = await axios.post('/api/video/getvideo', {categ: categ, start:start, limit:limit});
				if(res.data && res.data.length>0){
					if(refresh==1){
						this.videos = res.data;
						this.showmorevideo = true;
						this.videoparams[0] += this.videoparams[1];
					}
					else this.videos = this.videos.concat(res.data);
				}
				if(res.data.length<this.videoparams[1]) this.showmorevideo = false;
			},
			morevideo(){
				this.getVideo(this.keyCategVideo.id, this.videoparams[0], this.videoparams[1], 0);
				this.videoparams[0] += this.videoparams[1];
			},
			async getcategvideo(){
				let res = await axios.post('/api/video/getcategvideo');
				if(res.data) this.categVideo = this.categVideo.concat(res.data);
			},
			changeCategVideo(){
				this.videoparams[0] = 0;
				this.getVideo(this.keyCategVideo.id, this.videoparams[0], this.videoparams[1], 1);
			},
			async getCases(method, data){
				let res = await axios.post('/api/cases/'+method, data);
				if(res && res.data) return res.data;
				return null;
			},
			async getCasesText(val, refresh){
				val = val || 0;
				refresh = refresh || 0;
				if(refresh==1){
					this.arrCasesTextParams[0] = 0;
				}
				let res = await this.getCases('getCasesText', {val:val, start: this.arrCasesTextParams[0], limit:this.arrCasesTextParams[1] });
				if(refresh==1){
					this.arrCasesText = [];
				}
				this.arrCasesText = this.arrCasesText.concat( res );
				this.arrCasesTextParams[0] += this.arrCasesTextParams[1];

				if(res.length < this.arrCasesTextParams[1]) this.showMoreText = false;
                else this.showMoreText = true;
			},
			async getRegion(){
				let res = await this.getCases('getRegion', {start: this.arrCasesParams[0], limit:this.arrCasesParams[1] });
				this.filterRegion = this.filterRegion.concat(res);
				//this.keyfilterRegion = this.filterRegion[0];
			},
			async getIndustry(){
				let res = await this.getCases('getIndustry', {start: this.arrCasesParams[0], limit:this.arrCasesParams[1] });
				this.filterIndustry = this.filterIndustry.concat(res);
			},
			async getAllCases(filter){
				let res = await this.getCases('getAllCases', {filter: filter, start: this.arrCasesParams[0], limit:this.arrCasesParams[1] });
				this.arrCases = this.arrCases.concat( res );
				this.arrCasesParams[0] += this.arrCasesParams[1];

				if(res.length < this.arrCasesParams[1]) this.showMoreCases = false;
				else this.showMoreCases = true;
			},
			changeFilterCasesText(){
				this.getCasesText(this.keyFilterCasesText.id, 1);
			},
			changefilterRegion(){
				this.arrCasesParams[0] = 0;
				this.arrCases = [];
				let filter = {};
				if(this.keyfilterIndustry && this.keyfilterIndustry.id) filter.industry = this.keyfilterIndustry.id;
				if(this.keyfilterRegion && this.keyfilterRegion.id) filter.region = this.keyfilterRegion.id;
				this.getAllCases(filter)
			},
			changefilterIndustry(){
				this.arrCasesParams[0] = 0;
				this.arrCases = [];
				let filter = {};
				if(this.keyfilterIndustry && this.keyfilterIndustry.id) filter.industry = this.keyfilterIndustry.id;
				if(this.keyfilterRegion && this.keyfilterRegion.id) filter.region = this.keyfilterRegion.id;
				this.getAllCases(filter);
			},
			filterUrl(url){
				if(url.substr(0,4)!='http') url = 'http://'+url;
				return url;
			}
		},
		computed:{
			lang(){
				return this.$root.lang;
			}
		},
		mounted(){
			//this.setLang();
			this.init();
		},
		created () {
			window.addEventListener('scroll', this.onScrollToBottom);
		},
		destroyed () {
			window.removeEventListener('scroll', this.onScrollToBottom);
		}
	}
</script>

<style src="../assets/less/clienttestimonials.less" lang="less"></style>

